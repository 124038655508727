import React, { useState, useEffect } from "react";
import { Box, Typography, Tab, Tabs, Divider, Skeleton } from "@mui/material";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { httpAPI } from "../../../AxiosApi.js";

const StudentDetailsPopup = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const [student, setStudent] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await httpAPI.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getStudent/${id}`
        );
        if (response.status === 200) {
          setStudent(response.data.response);
        } else {
          console.error("Failed to fetch student details");
        }
      } catch (error) {
        console.error("Error fetching student details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGoBack = () => {
    navigate("/dashboard/student");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled
              style={{ fontSize: "30px", color: "#212529" }}
            />
          </span>
          <Typography variant="h5" style={{ margin: "0" }}>
            Student Details
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "auto",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              background: "#eee",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Tab
              label="Personal Details"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
            <Tab
              label="Institute Details"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  borderRadius: "5px",
                },
              }}
            />
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          padding: "1rem",
          background: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
        }}
      >
        {loading || !student ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "1rem",
              }}
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
          </>
        ) : (
          <>
            {currentTab === 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Student ID:
                  </Typography>
                  <Typography variant="body1">{student.studentId}</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Name:
                  </Typography>
                  <Typography variant="body1">{student.fullName}</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Email:
                  </Typography>
                  <Typography variant="body1">{student.email}</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Date of Birth:
                  </Typography>
                  <Typography variant="body1">
                    {student.dateOfBirth?.substring(0, 10)}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Phone:
                  </Typography>
                  <Typography variant="body1">{`${student.phone?.countryCode} - ${student.phone?.phoneNumber}`}</Typography>
                </Box>
              </>
            )}
            {currentTab === 1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Name:
                  </Typography>
                  <Typography variant="body1">
                    {student.instituteName ? student.instituteName : "NA"}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Phone No:
                  </Typography>
                  <Typography variant="body1">
                    {student.phone
                      ? `${student.phone?.countryCode}-${student.phone?.phoneNumber}`
                      : "NA"}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "600",
                      fontStyle: "italic",
                      fontSize: "22px !important",
                      textDecorationLine:' underline'
                    }}
                  >
                    Address
                  </Typography>
                  
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: " column",
                      width: "100%",
                      gap: " 1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Street:
                      </Typography>
                      <Typography variant="body1">
                        {student.instituteAddress
                          ? student.instituteAddress.street
                          : "NA"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        City:
                      </Typography>
                      <Typography variant="body1">
                        {student.instituteAddress
                          ? student.instituteAddress?.city
                          : "NA"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        State:
                      </Typography>
                      <Typography variant="body1">
                        {student.instituteAddress
                          ? student.instituteAddress?.state
                          : "NA"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Pincode:
                      </Typography>
                      <Typography variant="body1">
                        {student.instituteAddress
                          ? student.instituteAddress?.pincode
                          : "NA"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Country:
                      </Typography>
                      <Typography variant="body1">
                        {student.instituteAddress
                          ? student.instituteAddress?.country
                          : "NA"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Date of Establishment:
                  </Typography>
                  <Typography variant="body1">
                    {student.instituteDateOfEstablishment.substring(0, 10)}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default StudentDetailsPopup;
