import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../Utils/UserContext.js";
import { useAuth } from "../Utils/AuthContext.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { httpAPI } from "../../AxiosApi.js";
import loginImg from "../../assets/images/loginAi.webp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaWhatsapp } from "react-icons/fa";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5", // Deep Blue
    },
    secondary: {
      main: "#f50057", // Pink
    },
  },
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(UserContext);
  const { login } = useAuth();
  const navigate = useNavigate();
  const [enable2FA, setEnable2FA] = useState(false);
  const [userId, setUserId] = useState();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/dashboard/latestMessage");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const verifyOtp = async (otp) => {
    try {
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/verifyOTP`,
        { otp, userId }
      );
      if (response.status === 200) {
        const authToken = response.data.response.authToken;
        const userObject = response.data.response.data;
        setUser(userObject);
        localStorage.setItem("authToken", authToken);
        localStorage.setItem("user", JSON.stringify(userObject));
        login(authToken);
        toast.success("You are successfully logedIn")
        navigate("/dashboard/latestMessage");
      } else {
        toast.error("OTP verification failed");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      toast.error("An error occurred during OTP verification");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await httpAPI.post(
          `${process.env.REACT_APP_BACKEND_URI}/auth/login`,
          values
        );

        if (response.status === 200) {
          setUserId(response.data.response.userId);
          toast.success("OTP send successfully to register number");
          setEnable2FA(true);
        } else {
          toast.error(`${response.data.message}`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            toast.error(error.response.data.message);
          } else {
            toast.error("An error occurred:", error.message);
          }
        }
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundImage: `url(${loginImg})`, backgroundSize: "cover" }}>
        <Box sx={{ width: "400px", p: 4, borderRadius: 4, boxShadow: 2, bgcolor: "#FFFFFFCC" }}>
          <Typography variant="h4" gutterBottom align="center" sx={{ color: "#3f51b5" }}>Welcome Back!</Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="email"
              label="Email Address"
              variant="outlined"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            {enable2FA && (
              <TextField
                id="otp"
                label="OTP"
                variant="outlined"
                type="text"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
                fullWidth
                margin="normal"
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helperText={formik.touched.otp && formik.errors.otp}
              />
            )}
            {!enable2FA && (
              <Button
                variant="contained"
                type="submit"
                fullWidth
                color="primary"
                size="large"
                sx={{ mt: 2, color: "#FFFFFF", bgcolor: "#f50057" }}
              >
                Log In
              </Button>
            )}

            {enable2FA && (
              <Button
                variant="outlined"
                fullWidth
                startIcon={<FaWhatsapp />}
                onClick={() => verifyOtp(formik.values.otp)}
                sx={{ mt: 2, color: "#FFFFFF", bgcolor: "#25D366" }}
              >
                Verify OTP
              </Button>
            )}

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <NavLink to="/forgetPassword" style={{ textDecoration: 'none', color: "#3f51b5" }}>Forgot Password?</NavLink>
            </Typography>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
