import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider } from './Components/Utils/UserContext.js';
import { AuthProvider } from './Components/Utils/AuthContext.js';
import PrivateRoute from './Components/Utils/PrivateRoute.js';
import ForgetPassword from './Components/Auth/ForgetPassword.js';
import UpdateProfile from './Components/Admin/UpdateProfile.js';
import ChangePassword from './Components/Admin/ChangePassword.js';
import Modal from 'react-modal';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Dashboard from './Components/Admin/Dashboard.js';
import InstitueDetailsPopup from './Components/Admin/DetailViewPopup/InstituteDetailPopup.js';
import StudentDetailsPopup from './Components/Admin/DetailViewPopup/StudentDetailsPopup.js';
import Login from './Components/Auth/Login.js';
import NewMessage from './Components/Admin/NewMessage.js';
import SendMessage from './Components/Admin/SendMessage.js';
import SearchMessage from './Components/Admin/SearchMessage.js';
import SignUp from './Components/Auth/SignUp.js';
import Map from './Components/Admin/Map.js';



function App() {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <UserProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard/latestMessage" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />}>
                <Route path="latestMessage" element={<PrivateRoute Component={NewMessage} />} />
                <Route path="searchMessage" element={<PrivateRoute Component={SearchMessage} />} />
                <Route path="viewStudent/:id" element={<PrivateRoute Component={StudentDetailsPopup} />} />
                <Route path="sendMessage" element={<PrivateRoute Component={SendMessage} />} />
                <Route path="viewInstitute/:id" element={<PrivateRoute Component={InstitueDetailsPopup} />} />
                <Route path="updateProfile" element={<PrivateRoute Component={UpdateProfile} />} />
                <Route path="changePassword" element={<PrivateRoute Component={ChangePassword} />} />
                <Route path="map" element={<PrivateRoute Component={Map} />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
