import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { httpAPI } from "../../AxiosApi.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Utils/UserContext.js";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const profilePicture = user?.profilePicture;
  const name = user?.fullName;

  const [selectedImage, setSelectedImage] = useState(profilePicture);

  useEffect(() => {
    if (profilePicture !== user?.profilePicture || name !== user?.fullName) {
      setIsModified(true); 
    }
  }, [user?.profilePicture, user?.fullName]);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string(),
    profilePicture: Yup.mixed().test(
      "fileType",
      "Only PNG, JPG, and JPEG formats are allowed",
      (value) => {
        if (!value) return true;
        return (
          ["image/png", "image/jpeg", "image/jpg"].includes(value.type) &&
          value.size <= 10485760 // Max file size 10MB
        );
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      fullName: name,
      profilePicture: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("fullName", values.fullName);
        formData.append("profilePicture", values.profilePicture);

        const response = await httpAPI.post(
          `${process.env.REACT_APP_BACKEND_URI}/admin/updateProfile`,
          formData
        );

        if (response.data) {
          const userObject = response.data.response;
          setUser(userObject);
          localStorage.setItem("user", JSON.stringify(userObject));
          toast.success("Updated Successfully");
          setIsSubmitting(false);
          navigate("/dashboard/latestMessage");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  });

  const handleImageChange = (e) => {
    const uploadedImage = e.target.files[0];
    formik.setFieldValue("profilePicture", uploadedImage);
    setSelectedImage(URL.createObjectURL(uploadedImage));
    setIsModified(true); // Set isModified to true when a field is modified
  };

  const onCancel = () => {
    navigate("/dashboard/student");
  };

  return (
    <Box className="update-profile" sx={{ boxSizing: "border-box" }}>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Box
          sx={{
            margin: "auto",
            width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" },
            padding: "2rem",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "200px",
                height: "200px",
                margin: "auto",
                border: "1px solid gray",
                maxHeight: "200px",
                borderRadius: "5px",
              }}
            >
              <img
                src={
                  selectedImage ||
                  formik.values.profilePicture ||
                  profilePicture
                }
                alt="Selected"
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2rem 0",
              }}
            >
              <input
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                id="profilePicture"
                name="profilePicture"
                type="file"
                onChange={handleImageChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="profilePicture">
                <Button variant="contained" component="span">
                  Upload Profile Picture
                </Button>
              </label>
              {formik.touched.profilePicture &&
                formik.errors.profilePicture && (
                  <Typography color="error" mt={1}>
                    {formik.errors.profilePicture}
                  </Typography>
                )}
            </Box>
          </Box>

          <Box>
            <TextField
              fullWidth
              id="fullName"
              name="fullName"
              label="Full Name"
              variant="outlined"
              value={formik.values.fullName || name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
              mb={2}
            />  
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button type="submit" variant="contained" color="primary">
                {isSubmitting ? <CircularProgress size={24} /> : "Save"}
              </Button>
              <Button type="button" variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateProfile;
