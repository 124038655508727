import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Utils/AuthContext.js";
import { httpAPI } from "../../AxiosApi.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        if (values.newPassword !== values.confirmPassword) {
          toast.error("Passwords do not match");
          return;
        }

        const response = await httpAPI.post(
          `${process.env.REACT_APP_BACKEND_URI}/auth/changePassword`,
          { oldPassword: values.oldPassword, newPassword: values.newPassword }
        );
        if (response.status === 200) {
          toast.success("Password changed successfully");
          logout();
          setIsSubmitting(false);
          navigate("/login");
        } else if (response.status === 201) {
          if (response.data && response.data.message) {
            toast.error(response.data.message);
          } else {
            window.alert("An error occurred while changing the password.");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  });

  const onCancel = () => {
    navigate("/dashboard/latestMessage");
  };

  // const handleGoBack = () => {
  //   navigate("/dashboard")
  // };

  return (
    <Box className="update-profile" sx={{ boxSizing: "border-box" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "1rem",
          background: "#eeeeee",
          borderRadius: "5px",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}>
          {/* <span style={{ cursor: 'pointer' }} onClick={handleGoBack}>
            <TbSquareRoundedArrowLeftFilled style={{ fontSize: '30px', color: '#212529' }} />
          </span> */}
          <h2 style={{ margin: "0" }}>Change Password</h2>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "70%", lg: "50%", xl: "40%" },
            margin: "auto",
            padding: { xs: "1rem", sm: "1rem", md: "2rem" },
            border: "1px solid #e1e1e1",
            borderRadius: "5px",
            boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.1)",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <TextField
                fullWidth
                type={showOldPassword ? "text" : "password"}
                id="oldPassword"
                name="oldPassword"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Old Password"
                error={
                  formik.touched.oldPassword &&
                  Boolean(formik.errors.oldPassword)
                }
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleOldPasswordVisibility}
                        edge="end"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="New Password"
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleNewPasswordVisibility}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Confirm Password"
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              className="buttons"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                margin: "1rem 0",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                onClick={onCancel}
                sx={{
                  padding: "0.5rem 1rem",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  padding: "0.5rem 1rem",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
              >
             {isSubmitting ? <CircularProgress size={24} /> : "Save"}                  
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
