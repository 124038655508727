import React, { useEffect, useState } from "react";
import { httpAPI } from "../../AxiosApi.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableSortLabel,
  Tabs,
  Tab,
  Box,
  Skeleton,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { FaArrowUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NewMessage() {
  const [resumeData, setResumeData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [user, setUser] = useState("");
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [assignTo, setAssignTo] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchData = async () => {
    try {
      const response = await httpAPI.get(`${process.env.REACT_APP_BACKEND_URI}/admin/user`);
      if (response.data && response.data.response) {
        const { resume, enquiry } = response.data.response;
        setResumeData(resume);
        setEnquiryData(enquiry);
      } else {
        console.error("Invalid data structure in the response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchUser = async () => {
    try {
      const response = await httpAPI.get(`${process.env.REACT_APP_BACKEND_URI}/admin/userList`);
      console.log(response.data.response)
      if (response.data && response.data.response) {

        setUser(response.data.response);
      } else {
        console.error("Invalid data structure in the response:", response.data);
      }
    } catch (error) {

    }
  }
  const handleChangeAssignTo = async (id, newValue) => {
    try {
      // Update the local state immediately
      setAssignTo({ ...assignTo, [id]: newValue });

      // Hit the API to update the assignTo value
      const response = await httpAPI.put(`${process.env.REACT_APP_BACKEND_URI}/admin/messages/${id}/assignTo`, { assignTo: newValue });

      if (response.status === 200) {
        toast.success("AssignTo updated successfully"); // Show success toast message
      }

      // Fetch data again to update the UI
      fetchData();
    } catch (error) {
      console.error("Error updating assignTo:", error);
      toast.error("Error updating assignTo");
    }
  };


  useEffect(() => {
    fetchData();
    fetchUser();
  }, []);

  const handleStatusChange = async (id) => {
    try {
      setStatusChanging(true); 
      const response = await httpAPI.post(`${process.env.REACT_APP_BACKEND_URI}/admin/messageStatus`, { messageId: id });

      if (response.status === 200) {
        toast.success("Status updated successfully"); 
      }

      fetchData();
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status");
    } finally {
      setStatusChanging(false); 
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeTab = (event, newTabIndex) => {
    setActiveTabIndex(newTabIndex);
  };

  const filteredData = activeTabIndex === 0 ? resumeData : enquiryData;

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));

  const handleSearchChange = (event) => {
    setGlobalFilter(event.target.value);
    setPage(0); // Reset page when searching
  };

  const filteredAndSortedData = sortedData.filter((row) => {
    const dateMatch = row.timestamp?.toLowerCase().includes(globalFilter.toLowerCase());
    const phoneNumberMatch = row.phoneNumber?.toLowerCase().includes(globalFilter.toLowerCase());
    return dateMatch || phoneNumberMatch;
  });




  return (
    <div className="student">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isStatusChanging}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="btnHeader">
        <Box className="search">
          <TextField
            value={globalFilter}
            type="search"
            onChange={handleSearchChange}
            label="Search"
          />
        </Box>
      </div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: "0.5rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          value={activeTabIndex}
          onChange={handleChangeTab}
          variant="fullWidth"
          aria-label="Active and Inactive Tabs"
          sx={{ background: "#eee", padding: "5px", borderRadius: "5px" }}
        >
          <Tab
            label="Resume"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#4caf50",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label="Enquiry"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#4caf50",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
        </Tabs>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Phone No.</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "timestamp"}
                  direction={orderBy === "timestamp" ? order : "asc"}
                  onClick={() => handleRequestSort("timestamp")}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {activeTabIndex === 0 ? "Resume" : "Enquiry"}
              </TableCell>
              <TableCell>Assign To</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Skeleton animation="wave" width="100%" />
                </TableCell>
              </TableRow>
            ) : filteredAndSortedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              filteredAndSortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{row.timestamp.substring(0, 10)}</TableCell>
                    <TableCell>
                      {activeTabIndex === 0 ? (
                        <a href={row.mediaUrl} target="_blank" rel="noopener noreferrer">
                          {row.mediaUrl ? "View" : "No Resume"}
                        </a>
                      ) : (
                        <span>{row.message.length > 30 ? row.message.substring(0, 30) + "..." : row.message}</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Select
                        value={assignTo[row._id] || ""}
                        onChange={(e) => handleChangeAssignTo(row._id, e.target.value)}
                        variant="outlined"
                        sx={{ width: "120px", height: "23px" }}
                      >
                        {user.map((user) => (
                          <MenuItem key={user.id} value={user.username}>
                            {user.username}
                          </MenuItem>
                        ))}
                      </Select>

                    </TableCell>
                    <TableCell>
                      <button
                        onClick={() => handleStatusChange(row._id)}
                        style={{
                          width: "60px",
                          cursor: "pointer",
                          backgroundColor: "#4caf50",
                          color: "#fff",
                          padding: "6px 8px",
                          borderRadius: "10px",
                          border: "0",
                          fontSize: "14px"
                        }}
                      >
                        {row?.status}
                      </button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredAndSortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // Check if array is undefined or null, and return an empty array if so
  if (!array) return [];

  // Continue with sorting if array is defined
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
