import React, { useEffect, useState } from "react";
import { httpAPI } from "../../AxiosApi.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableSortLabel,
  Tabs,
  Tab,
  Box,
  Skeleton,
  Popover,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaReply } from "react-icons/fa";


export default function SearchMessage() {
  const [loading, setLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [messageType, setMessageType] = useState("all");
  const [resumeData, setResumeData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchData = async () => {
    try {
      const response = await httpAPI.get(
        `${process.env.REACT_APP_BACKEND_URI}/admin/allChat`
      );
      if (response.data && response.data.status) {
        const { resume, enquiry } = response.data.response;
        setResumeData(resume);
        setEnquiryData(enquiry);
      } else {
        console.error(
          "Invalid data structure in the response:",
          response.data
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDateRangePickerOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDateRangePickerOpen(false);
  };

  const handleStatusChange = async (id) => {
    try {
      setStatusChanging(true);
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URI}/admin/messageStatus`,
        { messageId: id }
      );

      if (response.status === 200) {
        toast.success("Status updated successfully");
        fetchData(); 
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Error updating status");
    } finally {
      setStatusChanging(false);
    }
  };
  const handleReply = (id, phone) => {
    setOpenModal(true);
    console.log("-=-=-=-=-=phone number", phone)
    setPhoneNumber(phone);
  };

  const handleSubmitReply = async () => {
    try {
      const response = await httpAPI.post(
        `${process.env.REACT_APP_BACKEND_URI}/admin/sendMessage`,
        { mobileNumber: phoneNumber, message: replyMessage }
      );
      if (response.status === 200) {
        toast.success("Message sent successfully");
        setOpenModal(false)

      } else {
        toast.error("Failed to send message");
      }
      setReplyMessage("");
      setPhoneNumber("");
    } catch (error) {
      console.log(error)
    }


  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeTab = (event, newTabIndex) => {
    setActiveTabIndex(newTabIndex);
  };

  const handleSearchChange = (event) => {
    setGlobalFilter(event.target.value);
    setPage(0);
  };

  const handleMessageTypeChange = (event) => {
    setMessageType(event.target.value);
  };

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
  };
  const filteredData =
    activeTabIndex === 0 ? resumeData : enquiryData;

  const sortedData = stableSort(filteredData, getComparator(order, orderBy));
  const filteredAndSortedData = sortedData.filter((row) => {
    const matchesGlobalFilter =
      Object.values(row).some((value) =>
        String(value)
          .toLowerCase()
          .includes(globalFilter.toLowerCase())
      ) ||
      row.timestamp
        ?.toLowerCase()
        .includes(globalFilter.toLowerCase());

    const matchesMessageType =
      messageType === "all" || row.messageType === messageType;

    return matchesGlobalFilter && matchesMessageType;
  });



  return (
    <div className="student">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isStatusChanging}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="btnHeader">
        <Box className="search">
          <TextField
            value={globalFilter}
            type="search"
            onChange={handleSearchChange}
            label="Search"
          />
        </Box>
        <div>
          <TextField
            id="date-picker"
            label="Date Range"
            value={`${dateRange[0]?.startDate.toLocaleDateString(
              "en-US"
            )} - ${dateRange[0]?.endDate.toLocaleDateString("en-US")}`}
            onClick={handleClick}
            readOnly
          />
          <Popover
            open={isDateRangePickerOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2}>
              <DateRangePicker
                onChange={handleDateRangeChange}
                ranges={dateRange}
              />
            </Box>
          </Popover>
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="message-type"
            name="messageType"
            value={messageType}
            onChange={handleMessageTypeChange}
            row
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="CustomerMessage"
              control={<Radio />}
              label="Sent"
            />
            <FormControlLabel
              value="AgentMessage"
              control={<Radio />}
              label="Received"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: "0.5rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          value={activeTabIndex}
          onChange={handleChangeTab}
          variant="fullWidth"
          aria-label="Active and Inactive Tabs"
          sx={{ background: "#eee", padding: "5px", borderRadius: "5px" }}
        >
          <Tab
            label="Resume"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#4caf50",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
          <Tab
            label="Enquiry"
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#4caf50",
                color: "#fff",
                borderRadius: "5px",
              },
            }}
          />
        </Tabs>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Phone No.</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "timestamp"}
                  direction={orderBy === "timestamp" ? order : "asc"}
                  onClick={() => handleRequestSort("timestamp")}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>
                {activeTabIndex === 0 ? "Resume" : "Enquiry"}
              </TableCell>
              <TableCell>Assign To</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reply</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Skeleton animation="wave" width="100%" />
                </TableCell>
              </TableRow>
            ) : sortedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) : (
              filteredAndSortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>
                      {new Date(row.timestamp).toLocaleDateString("en-US")}
                    </TableCell>
                    <TableCell>{row.messageType}</TableCell>
                    <TableCell>
                      {activeTabIndex === 0 ? (
                        <a
                          href={row.mediaUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.mediaUrl ? "View" : "No Resume"}
                        </a>
                      ) : (
                        <span>
                          {row.message.length > 20
                            ? row.message.substring(0, 20) + "..."
                            : row.message}
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{row?.assignTo}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleStatusChange(row._id)}
                        variant="contained"
                        style={{
                          backgroundColor: "#4caf50",
                          color: "#fff",
                          borderRadius: "10px",
                        }}
                      >
                        {row?.status}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => handleReply(row._id, row.phoneNumber)}
                        startIcon={<FaReply />}
                      >
                        Reply
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredAndSortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Reply to Message</DialogTitle>
        <DialogContent sx={{ width: "500px", marginTop: "10px" }}>
          <TextField
            label="Your Reply"
            multiline
            rows={4}
            value={replyMessage}
            onChange={(e) => setReplyMessage(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleSubmitReply} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (!array) return [];

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
