import React, { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

// Replace 'YOUR_MAPBOX_ACCESS_TOKEN' with your actual Mapbox access token
mapboxgl.accessToken = 'pk.eyJ1Ijoid2Utd2hlZWwiLCJhIjoiY2x1anhmNHMwMGg2ejJqbG1qZmx2eXY3ayJ9.nSUkyCLXFOW-sst1FlPHJg';

const MapComponent = () => {
  const [map, setMap] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const initializeMap = () => {
      const mapInstance = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-74.5, 40],
        zoom: 9,
      });

      mapInstance.on('load', () => {
        setMap(mapInstance);

        mapInstance.on('click', (e) => {
          const { lng, lat } = e.lngLat;

          if (!startPoint) {
            setStartPoint({ lng, lat });
            addMarker('start', { lng, lat });
          } else if (!endPoint) {
            setEndPoint({ lng, lat });
            addMarker('end', { lng, lat });
            getDirections({ lng, lat });
          }
        });
      });
    };

    initializeMap();

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [startPoint, endPoint]);

  const addMarker = (type, coordinates) => {
    if (map) {
      new mapboxgl.Marker({ color: type === 'start' ? 'green' : 'red' })
        .setLngLat(coordinates)
        .addTo(map);
    }
  };

  const getDirections = (endCoordinates) => {
    if (map && startPoint) {
      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint.lng},${startPoint.lat};${endCoordinates.lng},${endCoordinates.lat}?geometries=geojson&steps=true&access_token=${mapboxgl.accessToken}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setDirections(data.routes[0].geometry.coordinates);
        })
        .catch((error) => {
          console.error('Error fetching directions:', error);
        });
    }
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <div id="map" style={{ width: '100%', height: '100%' }} />
      {directions && (
        <div>
          <h2>Directions:</h2>
          <ul>
            {directions.map((coord, index) => (
              <li key={index}>{`[${coord.join(', ')}]`}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
