import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Collapse,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../Utils/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import instituteIcon from "../../assets/svgs/institute.svg";
import studentIcon from "../../assets/svgs/student.svg";
import scholarshipIcon from "../../assets/svgs/scholarship.svg";
import fundRequestIcon from "../../assets/svgs/fund-request.svg";
import patronIcon from "../../assets/svgs/patron.svg";
import logoutIcon from "../../assets/svgs/logout.svg";
import settingsIcon from "../../assets/svgs/settings.svg";
import passwordIcon from "../../assets/svgs/password.svg";
import wallet from "../../assets/svgs/wallet.svg";
import profileIcon from "../../assets/svgs/profile.svg";
import { UserContext } from "../Utils/UserContext.js";


const Sidebar = () => {
    const { user } = useContext(UserContext);
     const { logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [openSettings, setOpenSettings] = useState(false);
    const handleLogout = () => {
        const confirmed = toast.success("You are successfully logged out");
        if (confirmed) {
            logout();
            navigate("/login");
        }
    };
    return (
        <Box
            className="sidebar"
            sx={{
                width: "100%",
                maxWidth: 260,
                height: "100vh",
                background: "#212529",
                border: "none",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
        >
            <Box>
                <Divider />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: " 10px",
                        padding: "2rem 0.5rem",
                    }}
                >
                    <img
                        src={user?.profilePicture}
                        alt=""
                        style={{
                            width: "70px",
                            height: "70px",
                            display: "block",
                            objectFit: "cover",
                            borderRadius: "50%",
                            border: "2px solid #fff",
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "14px", fontWeight: "800", color: "#fff" }}
                        >
                            {user?.fullName}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                            {user?.email}
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ width: "50%", margin: "auto" }} />
                <List className="menu-list" sx={{ width: "100%", background: "#212529" }} component="nav">
                    <ListItemButton
                        component={NavLink}
                        to="/dashboard/latestMessage"
                        sx={{
                            backgroundColor: location.pathname === "/dashboard/student" ? "#107a0a" : "transparent",
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <img src={studentIcon} className="sidebarmenu-icon" />
                        </ListItemIcon>
                        <ListItemText primary="Latest Message" sx={{ color: "#fff" }} />
                    </ListItemButton>

                    <ListItemButton
                        component={NavLink}
                        to="/dashboard/sendMessage"
                        sx={{
                            backgroundColor: location.pathname === "/dashboard/institute" ? "#107a0a" : "transparent",
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <img src={instituteIcon} className="sidebarmenu-icon" />
                        </ListItemIcon>
                        <ListItemText primary="Send Message" sx={{ color: "#fff" }} />
                    </ListItemButton>
                  
                    <ListItemButton
                        component={NavLink}
                        to="/dashboard/searchMessage"
                        sx={{
                            backgroundColor: location.pathname === "/dashboard/institute" ? "#107a0a" : "transparent",
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <img src={fundRequestIcon} className="sidebarmenu-icon" />
                        </ListItemIcon>
                        <ListItemText primary="Search Message" sx={{ color: "#fff" }} />
                    </ListItemButton>
                    

                 

                  

                    <ListItemButton
                        onClick={() => setOpenSettings(!openSettings)}
                        sx={{ backgroundColor: openSettings ? "#107a0a" : "transparent" }}
                    >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <img src={settingsIcon} className="sidebarmenu-icon" />
                        </ListItemIcon>
                        <ListItemText primary="Settings" sx={{ color: "#fff" }} />
                    </ListItemButton>
                    <Collapse in={openSettings} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to="/dashboard/changePassword"
                                sx={{
                                    backgroundColor: location.pathname === "/dashboard/changePassword" ? "#107a0a" : "transparent",
                                    paddingLeft: "40px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "40px" }}>
                                    <img src={passwordIcon} className="sidebarmenu-icon" />
                                </ListItemIcon>
                                <ListItemText primary="Change Password" sx={{ color: "#fff", fontSize: "12px" }} />
                            </ListItemButton>

                            <ListItemButton
                                component={NavLink}
                                to="/dashboard/updateProfile"
                                sx={{
                                    backgroundColor: location.pathname === "/dashboard/updateProfile" ? "#107a0a" : "transparent",
                                    paddingLeft: "40px",
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: "40px" }}>
                                    <img src={profileIcon} className="sidebarmenu-icon" />
                                </ListItemIcon>
                                <ListItemText primary="Update Profile" sx={{ color: "#fff", fontSize: "12px" }} />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
            </Box>

            <Box sx={{ background: "#212529", padding: "10px" }}>
                <List sx={{ width: "100%", background: "#212529" }} component="nav">
                    <ListItemButton onClick={handleLogout} sx={{ background: "gray" }}>
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                            <img src={logoutIcon} className="sidebarmenu-icon" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" sx={{ color: "#fff" }} />
                    </ListItemButton>
                </List>
            </Box>
        </Box>
    );
};

export default Sidebar;

