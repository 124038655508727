import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField, Button, CircularProgress, Box, Typography, ThemeProvider, createTheme } from "@mui/material";
import forgetPasswordImg from "../../assets/images/loginAi.webp";
import { TbSquareRoundedArrowLeftFilled } from "react-icons/tb";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5", // Deep Blue
    },
    secondary: {
      main: "#f50057", // Pink
    },
  },
});

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (!isSendingEmail) {
          setIsSendingEmail(true);
          const response = await axios.post(
            `http://3.17.230.67/api/auth/forgetPassword`,
            values
          );
          if (response.status === 200) {
            toast.success("Forget Password Sent Successfully on your Email");
          } else if (response.status === 201) {
            toast.error(response?.data?.message);
          } else {
            toast.error("Unexpected Error: Please try again later.");
          }
          setIsSendingEmail(false);
        } else {
          toast.warning("Please wait before sending another email.");
        }
      } catch (error) {
        console.error("Error sending reset email:", error);
        toast.error("Error sending reset email. Please try again later.");
        setIsSendingEmail(false);
      }
    },
  });

  const handleGoBack = () => {
    navigate("/login");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundImage: `url(${forgetPasswordImg})`, backgroundSize: "cover", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ width: "400px", p: 4, borderRadius: 4, boxShadow: 2, bgcolor: "#FFFFFFCC" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "1rem", mb: 2 }}>
            <span style={{ cursor: "pointer" }} onClick={handleGoBack}>
              <TbSquareRoundedArrowLeftFilled style={{ fontSize: "30px", color: "#212529" }} />
            </span>
            <Typography variant="h6" style={{ margin: "0", color: "#212529" }}>Back</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", gap: "1rem" }}>
            <Typography variant="h4" style={{ color: "#3f51b5" }}>Forgot Password</Typography>
            <Typography variant="body1">Please enter your registered email to receive a password reset link.</Typography>
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={isSendingEmail}
                sx={{
                  background: "#f50057", // Pink
                  color: "#fff", // White
                  padding: "0.8rem",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                {isSendingEmail ? (
                  <CircularProgress size={24} />
                ) : (
                  "Send Reset Email"
                )}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </ThemeProvider>
  );
}
