import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Button,
    TextField,
    Typography,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import { httpAPI } from "../../AxiosApi.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginImg from "../../assets/images/loginAi.webp";

const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
    phone: Yup.string().required("Phone number is required"),
    profileImage: Yup.mixed().required("Profile picture is required"),
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#3f51b5", // Deep Blue
        },
        secondary: {
            main: "#f50057", // Pink
        },
    },
});

const SignUp = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
            password: "",
            phone: "",
            profileImage: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const formData = new FormData();
                formData.append("username", values.username);
                formData.append("email", values.email);
                formData.append("password", values.password);
                formData.append("phone", values.phone);
                formData.append("profileImage", values.profileImage);
                const response = await httpAPI.post(
                    `${process.env.REACT_APP_BACKEND_URI}/auth/signup`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.status === 200) {
                    toast.success("Signup successful. Please log in.");
                    navigate("/login");
                } else {
                    toast.error(`${response.data.message}`);
                }
            } catch (error) {
                console.error("Error during signup:", error);
                toast.error("An error occurred during signup");
            }
        },
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleFileChange = (event) => {
        formik.setFieldValue("profileImage", event.currentTarget.files[0]); // Update profile image value
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundImage: `url(${loginImg})`,
                    backgroundSize: "cover",
                }}
            >
                <Box
                    sx={{
                        width: "400px",
                        p: 4,
                        borderRadius: 4,
                        boxShadow: 2,
                        bgcolor: "#FFFFFFCC",
                    }}
                >
                    <Typography
                        variant="h4"
                        gutterBottom
                        align="center"
                        sx={{ color: "#3f51b5" }}
                    >
                        Sign Up
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            id="username"
                            label="Username"
                            variant="outlined"
                            type="text"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            fullWidth
                            margin="normal"
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                        />
                        <TextField
                            id="email"
                            label="Email Address"
                            variant="outlined"
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            fullWidth
                            margin="normal"
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            fullWidth
                            margin="normal"
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            id="phone"
                            label="Phone Number"
                            variant="outlined"
                            type="text"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            fullWidth
                            margin="normal"
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                        <label htmlFor="profileImage" style={{ display: "block", marginBottom: "8px", color: "#3f51b5" }}>
                            Profile Picture
                        </label>
                        <input
                            id="profileImage"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ marginBottom: "15px", display: "block" }}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            color="primary"
                            size="large"
                            sx={{ mt: 2, color: "#FFFFFF", bgcolor: "#3f51b5" }}
                        >
                            Sign Up
                        </Button>
                        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                            Already have an account?{" "}
                            <RouterLink to="/login" style={{ textDecoration: "none", color: "#3f51b5" }}>
                                Log In
                            </RouterLink>
                        </Typography>
                    </form>
                </Box>
            </Box>
            <ToastContainer />
        </ThemeProvider>
    );
};

export default SignUp;
