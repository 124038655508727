import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { httpAPI } from "../../AxiosApi.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SendMessage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const response = await httpAPI.post(
          `${process.env.REACT_APP_BACKEND_URI}/admin/sendMessage`,
          { mobileNumber: values.mobileNumber, message: values.message }
        );
        if (response.status === 200) {
          toast.success("Message sent successfully");
          setIsSubmitting(false);
          formik.setValues({
            mobileNumber: "",
            message: "",
          });
        } else {
          toast.error("Failed to send message");
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
  });

  const onCancel = () => {
    navigate("/dashboard/latestMessage");
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Send Message
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            type="tel"
            id="mobileNumber"
            name="mobileNumber"
            label="Mobile Number"
            value={formik.values.mobileNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mobileNumber &&
              Boolean(formik.errors.mobileNumber)
            }
            helperText={
              formik.touched.mobileNumber && formik.errors.mobileNumber
            }
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            id="message"
            name="message"
            label="Message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            sx={{ marginBottom: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={onCancel}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{ textTransform: "none" }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Send"}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default SendMessage;
